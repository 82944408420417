@import "~antd/dist/antd.css";
@import "~overlayscrollbars/css/OverlayScrollbars.css";
@import '~swiper/swiper.scss';
@import '~swiper/modules/pagination/pagination.scss';
@import url(https://fonts.googleapis.com/css?family=Sacramento);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  color: #404040;
  font: 400 15px/22px "Rubik", "Source Sans Pro", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

/* h1 {
  font-size: 22px;
  margin: 0;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 2px;
} */

a {
  color: #404040;
  text-decoration: none;
}

a:hover {
  color: #101010;
}

/* The page is split between map and sidebar - the sidebar gets 1/3, map
gets 2/3 of the page. You can adjust this to your personal liking. */
.sidebar {
  position: relative;
  width: 33.3333%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
}
.menu {
  width: 100%;
  padding: 0 20px;
  line-height: 63px;
  border: 0;
}
@media (max-width: 48em) {
  .menu {
    padding: 0;
  }
}
.app-header {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  padding: 0;
}
.app-header__logo-search-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.app-header__logo {
  display: inline-block;
  padding: 0 20px;
}
.app-header__logo img {
  width: 36px;
  animation: fadeIn 1s; 
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.app-header__search-input {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
}
.app-header__search-input .ant-input-group {
  border: 0;
  width: 100%;
  max-width: 460px;
}
.app-header__search-input .ant-input-group input {
  border: 1px solid #f0f1f2;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.app-header__menu-section {
  float: right;
}
.app-header-skeleton {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  padding: 0;
}
.app-header-skeleton__search-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.app-header-skeleton__logo {
  padding: 0 20px;
}
.app-header-skeleton__logo img {
  width: 55px;
}
.app-header-skeleton__search-input {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
}
.app-header-skeleton__search-input .ant-input-group {
  border: 0;
  width: 100%;
  max-width: 460px;
}
.app-header-skeleton__search-input .ant-input-group input {
  border: 1px solid #f0f1f2;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.error-banner {
  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  z-index: 99;
}
.listing-card__cover-img {
  width: 100%;
  height: 195px;
  background-size: cover;
  background-position: 50%;
}
.listing-card__rating-tag {
  position: absolute;
  top: 5px;
  right: 0;
}
.listing-card__description {
  padding-bottom: 20px;
}
.listing-card__price.ant-typography {
  color: #1d226c;
}
.listing-card__price.ant-typography span {
  color: #bfbfbf;
  font-weight: 400;
}
.listing-card__title.ant-typography,
.listing-card__address.ant-typography {
  display: block;
}
.listing-card__dimensions i,
.listing-card__dimensions span {
  padding-right: 10px;
  font-size: 13px;
}
.listing-card__dimensions span {
  color: #bfbfbf;
}
.listing-card__dimensions--guests {
  float: right;
}
.listing-card__dimensions--guests span {
  padding-right: 0;
}
@media (max-width: 75em) {
  .listing-card__dimensions--guests {
    float: none;
    padding-bottom: 5px;
  }
}
@media (max-width: 48em) {
  .listing-card__dimensions--guests {
    float: right;
    padding-bottom: 0;
  }
}
.home-hero,
.page-skeleton__paragraph {
  padding-bottom: 40px;
}
.home-hero {
  width: 100%;
}
.home-hero__search {
  max-width: 600px;
}
.home-hero__title.ant-typography {
  color: #1d226c;
}
.home-hero__search-input input {
  border: 1px solid #f0f1f2;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.home-hero__cards {
  padding-top: 60px;
}
.home-hero__cards .ant-card {
  background: transparent;
  cursor: pointer;
}
.home-hero__cards .ant-card-cover:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d226c;
  opacity: 0.5;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.home-hero__cards .ant-card-cover:hover:before {
  background: none;
}
.home-hero__cards .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  color: #fff;
  font-weight: 700;
  position: relative;
  bottom: 40px;
}
.home-listings {
  padding: 40px 0 20px;
}
.home-listings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.home-listings-skeleton {
  padding: 40px 0 20px;
}
.home-listings-skeleton__card {
  margin: 5px 0;
}
.home-listings-skeleton__card-cover-img {
  width: 100%;
  height: 195px;
}
.home {
  padding: 60px 120px;
  background-image: url(/sections/Home/assets/map-background.jpg);
  background-repeat: no-repeat;
  background-position: 10% 0;
}
@media (max-width: 75em) {
  .home {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.home__cta-section {
  padding: 40px 0;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}
.home__cta-section-title.ant-typography {
  color: #1d226c;
}
.home__cta-section-button {
  margin-top: 20px;
}
.home__listings {
  padding: 40px 0 20px;
}
.home__listings-title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.home__listings-img-cover {
  width: 100%;
  cursor: pointer;
}
@media (max-width: 36em) {
  .home__listings-img-cover {
    padding: 10px 0;
  }
}
.home__listings-img {
  width: 100%;
}
.host-content {
  display: flex;
  justify-content: center;
  // align-items: center;


  & > div {
    flex-grow: 1;
    padding: 40px 100px;
    max-width: 1100px;
  }
  // margin: 0 auto;
}
@media (max-width: 62em) {
  .host-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.host__form-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 50px;
}

.host__form-title.ant-typography {
  color: #02020B;
  margin-bottom: 5px;
  font-size: 24px;
  padding-bottom: 10px
}

.host__form-image-upload img {
  width: 125px;
}
.listing-bookings h3.ant-typography,
.listing-bookings h4.ant-typography {
  color: #1d226c;
}
.listing-bookings__section {
  font-size: 15px;
  padding: 5px 0;
}
.listing-bookings__item {
  margin: 20px 0;
}
.listing-bookings__history {
  margin-bottom: 20px;
}
.listing-bookings__history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.listing-bookings__stars {
  font-size: 13px;
}
.listing-booking, .listing-delete-btn {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}
.listing-delete-btn {
  margin-bottom: 20px;
}
@media (max-width: 62em) {
  .listing-booking {
    padding-top: 40px;
  }
}
.listing-booking__card-title.ant-typography {
  color: #1d226c;
  margin-bottom: 5px;
}
.listing-booking__card-title.ant-typography span {
  color: #bfbfbf;
  font-weight: 400;
}
.listing-booking__card {
  width: 100%;
  text-align: center;
}
.listing-booking__card-date-picker {
  padding-bottom: 20px;
}
.listing-booking__card-cta {
  display: block;
  margin: 0 auto 10px;
}
.ant-calendar-date__check-in {
  border: 1px solid #1890ff;
  border-radius: 50%;
}
.ant-calendar-footer-text {
  font-size: 11px;
  line-height: 20px;
  padding: 10px;
  display: block;
}
.listing-booking-modal {
  text-align: center;
  padding-top: 10px;
}
.listing-booking-modal__intro-title.ant-typography,
.listing-booking-modal__intro-title.ant-typography + h3.ant-typography {
  margin-top: 5px;
}
.listing-booking-modal__charge-summary-total {
  font-weight: 700;
  font-size: 15px;
}
.listing-booking-modal__charge-summary-stripe {
  display: block;
  font-size: 11px;
  padding-top: 5px;
}
.listing-booking-modal__stripe-card-section {
  padding-bottom: 10px;
}
.listing-booking-modal__stripe-card {
  padding: 10px;
  margin: 40px 0;
  border: 1px solid #f0f1f2;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.listing-booking-modal__cta {
  width: 100px;
}
.listing-details h3.ant-typography,
.listing-details h4.ant-typography {
  color: #1d226c;
}
.listing-details__image {
  width: 100%;
  height: 570px;
  background-size: cover;
  background-position: 50%;
  margin-bottom: 20px;
}
@media (max-width: 75em) {
  .listing-details__image {
    height: 380px;
  }
}
.listing-details__title.ant-typography {
  margin-top: 5px !important;
  margin-bottom: 5px;
}
.listing-details__title.ant-typography span {
  color: #bfbfbf;
  font-weight: 400;
}
.listing-details__city-address.ant-typography,
.listing-details__ratings.ant-typography {
  margin: 5px 0;
}
.listing-details__ratings.ant-typography {
  font-size: 13px;
}
.listing-details__host-name {
  font-family: Sacramento, cursive;
  display: inline-block;
  padding-left: 10px;
  position: relative;
  top: 5px;
}
.listing-details__section {
  font-size: 15px;
  padding: 5px 0;
}
.listing-details__about-items {
  padding-bottom: 20px;
}
.listing-details__amenities-row {
  padding: 5px 0;
}
.listing-details__amenities-row i,
.listing-details__amenities-row span {
  padding: 0 2.5px;
}
.listing-details__booking-item {
  margin: 20px 0;
}
.listing-details__booking-history {
  margin-bottom: 20px;
}
.listing-details__booking-history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.listing-details__booking-stars {
  font-size: 13px;
}
.listing {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .listing {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.listings-filters {
  padding: 10px 0;
  background: #fff;
}
.listings-filters
  .ant-select-selection--single
  .ant-select-selection__rendered {
  margin-right: 30px;
}
.listings-filters span {
  padding-right: 5px;
}
.listings-pagination {
  padding: 10px 0;
  float: right;
}
@media (max-width: 48em) {
  .listings-pagination {
    float: none;
  }
}
.listings-skeleton__card {
  margin: 5px 0;
}
.listings-skeleton__card-cover-img {
  width: 100%;
  height: 195px;
}
.listings {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .listings {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.listings--none .ant-typography {
  font-size: 15px;
}
.listings__affix {
  background: #fff;
  padding: 10px 0;
}
.listings__title.ant-typography {
  color: #1d226c;
}
.log-in {
  display: flex;
  align-items: center;
  justify-content: center;
}
.log-in-card {
  width: 450px;
  text-align: center;
  padding: 10px 0 20px;
}
.log-in-card__intro-title.ant-typography,
.log-in-card__intro-title.ant-typography + h3.ant-typography {
  margin-top: 5px;
}
.log-in-card__google-button {
  margin: 40px auto;
  border-radius: 2px;
  background-color: #4285f4;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border: none;
  display: flex;
  align-items: center;
  padding: 1px;
  color: #fff;
  cursor: pointer;
}
.log-in-card__google-button:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.log-in-card__google-button:active {
  background: #3367d6;
}
.log-in-card__google-button-logo {
  height: 43px;
}
.log-in-card__google-button-text {
  text-align: left;
  white-space: nowrap;
  padding: 10px;
}
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.not-found__description-title.ant-typography {
  display: block;
  padding-bottom: 10px;
  color: #1d226c;
  font-size: 22px;
  font-weight: 700;
}
.not-found__description-subtitle.ant-typography {
  color: #1d226c;
  font-size: 16px;
}
.not-found__cta {
  margin-top: 20px;
}
.stripe {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-bookings {
  padding-top: 40px;
}
.user-bookings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.user-bookings__description.ant-typography {
  font-size: 15px;
}
.user-bookings .ant-list-pagination {
  padding-bottom: 20px;
}
.user-bookings__booking-item {
  margin: 20px 0;
}
.user-bookings__booking-history {
  margin-bottom: 20px;
}
.user-bookings__booking-history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.user-bookings__booking-stars {
  font-size: 13px;
}
.user-listings {
  padding-top: 40px;
}
.user-listings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.user-listings .ant-list-pagination {
  padding-bottom: 20px;
}
.user-listings__description.ant-typography {
  font-size: 15px;
}
.user-profile {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}
.user-profile__card {
  width: 100%;
}
.user-profile__avatar {
  text-align: center;
}
.user-profile__details h4.ant-typography {
  color: #1d226c;
}
.user-profile__details div.ant-typography {
  font-size: 15px;
}
.user-profile__details-cta {
  margin-bottom: 20px;
}
.user {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .user {
    padding-left: 20px;
    padding-right: 20px;
  }
}
html {
  overflow: auto;
}
#root,
body,
html {
  height: 100%;
  width: 100%;
}
#app {
  position: relative;
  background: #fff;
  min-height: 100vh;
}
.app-skeleton {
  height: 100%;
  background: #fff;
  display: flex;
}
.app-skeleton__spin-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app__affix-header {
  z-index: 99;
}
.app__affix-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media (max-width: 36em) {
  .app__affix-footer {
    display: none;
  }
}

a:hover {
  color: #101010;
}

/* The page is split between map and sidebar - the sidebar gets 1/3, map
  gets 2/3 of the page. You can adjust this to your personal liking. */
.sidebar {
  position: absolute;
  width: 33.3333%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
}
.listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.listings .item {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}

.listings .item .title {
  display: block;
  color: #00853e;
  font-weight: 700;
}

.listings .item .title small {
  font-weight: 400;
}

.listings .item.active .title,
.listings .item .title:hover {
  color: #8cc63f;
}

.listings .item.active {
  background-color: #f8f8f8;
}

.body-scrollbars {
  height: 100vh;
}

.top__bttn {
  width: 100%;
  background-color: #000;
}

.top__bttn-text {
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  display: block;
  max-width: 370px;
  font-weight: 400;
  line-height: 1em;
  padding: 12px 0 12px 0;
  position: relative;
}

.top__bttn-text::before {
  content: "";
  background-image: url(./imgs/🇺🇦.svg);
  background-size: 100% 100%;
  display: block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0 auto -20px;
  position: absolute;
}
header {
  background-color: #EDF5FC;
  width: 100%;
}

.secondary {
  background-color: #FFF;
  width: 100%;
}

.secondary::after {
  border: 1px solid #EDF5FC; 
}

.menu-bar {
  width: 100%;
  margin-top: 20px;
}

header::after {
  content: '';
  border: 1px solid #EDF5FC;
  height: 2px;
  display: block;
}

.header__container {
  padding-right: 80px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 30px;
  align-items: center;
  position: relative;

  @media screen and (max-width: 900px) {
    padding-right: 40px;
  }

  @media screen and (max-width: 800px) {
    padding-right: 20px;
  }

}

.header__buttons {
  /* margin-right: 10px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.collection__logo {
  width: 100%;
  max-width: 225px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.header-logo {
  padding-left: 100px;
  max-height: 54px;
  max-width: 107px;

  @media screen and (max-width: 900px) {
    padding-left: 60px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 20px;
  }
}

.mappitall-logo {
  margin-left: 20px;
  margin-right: 50px;

  @media screen and (max-width: 650px) {
    margin-left: 10px;
  }
}

.language {
  font-size: 15px;
  font-weight: 400;
  color: #757575;
  position: relative;
}

.language__button {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.divider {
  position: relative;
  margin: auto 0 auto 30px;
}

.vertical {
  margin-left: 120px;
  position: relative;

  @media screen and (max-width: 900px) {
    margin-left: 80px;
  }

  @media screen and (max-width: 900px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 650px) {
    margin-left: 30px;
  }
}

.language::before {
  content: "";
  background-image: url(./imgs/world-map.svg);
  background-size: 100% 100%;
  display: block;
  width: 16px;
  height: 16px;
  max-height: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto 0 auto -20px;
}

.language::after {
  content: "";
  background-image: url(./imgs/vector-arrow.svg);
  background-size: 100% 100%;
  display: block;
  width: 11px;
  height: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto 0 auto 25px;
}

.collection__button {
  width: 100%;
  max-height: 40px;
  border-radius: 100px;
  background-color: #4095DA;
  margin-left: 15px;
  margin-right: 15px;

  
  @media screen and (max-width: 768px) {
    display: none;
  }

}

.collection__button:hover {
  background-color: #2882cc;
  transition: 0.3s linear;
}

.collection__button-link {
  width: 100%;
}

.collection__button-text {
  padding: 10px 20px 10px 40px;
  text-align: center;
  color: #fff;
  position: relative;
}

.collection__button-text::before {
  content: "";
  background-image: url(./imgs/plus-icon.svg);
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto 0 15px 20px;
}

.sing-in__button-link {
  color: #4095DA;
  display: inline;
}

.sing-in__button-link:hover {
  color: #000;
  text-decoration: underline;
  transition: 0.5s ease;
}

.sing-in__button-text { 
  padding-left: 15px;
  padding-top: 15px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
}

.sing-in-icon {
  display: none;
}

.home-page__body {
  background-color: #EDF5FC;
}

.search__container {
  display: flex;
  justify-content: center;
  background: url(./imgs/bg_map.png) no-repeat -50px -100px;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  padding: 0 20px 30px;
}

.search__container-title {
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
  line-height: 1.2;
}

.search-bar {
  width: 386px;
  border: 1px solid #000;
  padding: 5px 5px 5px 50px;
  height: 45px;
  background: url(./imgs/icon.search.svg) no-repeat 3px 1px;
  background-size: auto 14px;
  background-position: center left 20px;
  border-radius: 500px;
  border: none;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0px 13px 10px rgba(50, 50, 71, 0.05), 0px 22px 28px rgba(50, 50, 71, 0.05);
}

.search__container-tip {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #757575;
}

.search-link {
  text-decoration: underline;
  color: #757575;
}

.hero__container {
  margin-top: 30px;
}

.main {
  width: 100%;
  margin-top: -35px;
  background-color: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 0 20px;
}

.hero-title {
  font-size: 18px;
  font-weight: 600;
  padding-top: 50px;
  text-align: center;
}

.cities__bar {
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 15px;

  @media screen and (max-width: 700px) {
    justify-content: center;
  }

}

.city__bar-item-wrap {
  margin-bottom: 10px;
  padding-right: 10px;
}

.city__bar-item {
  width: 100%;
  // max-width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #F2F2F2;
  border-radius: 100px;
  transition: 0.3s linear;
}

.city__bar-item-text {
  margin: 0 10px;
}

.city__bar-item h5 {
  margin: 0;
}

.city__bar-item-title {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
}

.city__bar-item-points {
  margin-bottom: -2px;
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.city__bar-item-link {
  width: 100%;
  max-width: 173px;
}

.city__bar-item:hover {
  box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08), 0px 24px 32px rgba(50, 50, 71, 0.08);
}

.cards__container {
  max-width: 1140px;
  margin: 50px auto;
}

.most-visited-places {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.collection__cards__grid {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
  grid-gap: 20px;
  justify-content:space-between;

  @media screen and (max-width:1200px) {
    grid-template-columns: repeat(3, auto);
  }

  @media screen and (max-width:900px) {
    grid-template-columns: repeat(2, auto);
  }

  @media screen and (max-width:768px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

}

.collection__cards__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 270px;
  height: 318px;
  position: relative;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  transition: 0.3s linear;
}

.collection__cards__item:hover {
  box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08), 0px 24px 32px rgba(50, 50, 71, 0.08);
}

.collection__cards__wrapper {
  margin: auto;
  text-align: center;
  color: #FFFFFF;
}

.cards-icon {
  position: absolute;
  top: 80px;
  left: 20px;
}

.count-info {
  position: absolute;
  top: 20px;
  right: 10px;
  padding-right: 10px;
  background-color: #FFFFFFB2;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.count__info-text {
  margin-top: 3px;
  margin-bottom: 2px;
  margin-left: 10px;
}

.collection__cards__text__wrapper {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.person-icon {
  margin-left: -15px;
}

.cards__item-title {
  font-size: 16px;
  font-weight: 500;
}

.cards__item-text {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  padding: 0px 0 0px 0;
}

.collection-points {
  font-size: 15px;
  font-weight: 400;
  color: #757575;
  text-align: center;
  margin-top: 30px;
}

.collection__cards__container {
  margin-top: 60px;
}

.collection__cards__container-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.add {
  background: linear-gradient(116.89deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 63.43%), url(./imgs/img.place.png) no-repeat;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  background-size: 100% 100%;
}

.bttn-add {
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
  margin: 0 auto;
}

.map__container {
  margin-top: 50px;
}

.map__container-title {
  padding-bottom: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

    
.breadcrumb {
  margin: 30px 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
}

.breadcrumb li {
  display: inline;
  font-size: 14px;
  font-weight: 400;
}

.breadcrumb li+li:before {
  padding: 15px;
  color: #757575;
  content: ">";
}

.breadcrumb li a {
  text-decoration: none;
  color: #757575;
}

.breadcrumb li a.active {
  color: #4095DA;
}

.breadcrumb li {
  display: inline;
  font-size: 14px;
  font-weight: 400;
}

.breadcrumb li a:hover {
  color: #4095DA;
  text-decoration: underline;
  transition: 0.3s ease;
}

.steps__container {
  width: 70%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
}

.steps-action {
  margin-top: 24px;
}

.home-map-wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.host-step-item {
  margin: 30px 0;
}
